import { render, staticRenderFns } from "./RkModal.vue?vue&type=template&id=70b5704d"
import script from "./RkModal.vue?vue&type=script&lang=js"
export * from "./RkModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkOverlay: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkOverlay/RkOverlay.vue').default,RkCloseButton: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkCloseButton/RkCloseButton.vue').default})

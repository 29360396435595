//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent } from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkCloseButton',
  props: {
    label: {
      type: String,
      default: 'Close',
    },
    selected: {
      type: Boolean,
      default: false,
    },
    darkTheme: {
      type: Boolean,
      default: false,
    },
  },
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rk-carousel",class:[
    { 'rk-carousel--loading': _vm.sliderLoading, 'rk-carousel--all': _vm.allSlidesShowing },
    _vm.showWhenLoading ]},[_c('div',{ref:"keen",staticClass:"keen-slider"},[_vm._t("default")],2),_vm._v(" "),(!_vm.sliderDisabled && _vm.arrows)?_c('button',{class:{
      'rk-carousel-arrow': true,
      'rk-carousel-arrow--left': true,
      'rk-carousel-arrow--inside': _vm.arrowsInside,
      'rk-carousel-arrow--higher': _vm.arrowsHigher,
      'rk-carousel-arrow--no-border': _vm.arrowsNoBorder,
      'rk-carousel-arrow--disabled': _vm.current === 0,
      hidden: !_vm.arrowsOnSmall,
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.slider.prev()}}},[_vm._t("prevArrow",function(){return [_c('div',{staticClass:"bg-white px-2 py-1",class:{ 'border border-gray-700': !_vm.arrowsNoBorder }},[_c('RkIcon',{staticClass:"h-8 w-8",attrs:{"icon":"arrow-left"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Previous')))])],1)]})],2):_vm._e(),_vm._v(" "),(!_vm.sliderDisabled && _vm.arrows)?_c('button',{class:{
      'rk-carousel-arrow': true,
      'rk-carousel-arrow--right': true,
      'rk-carousel-arrow--inside': _vm.arrowsInside,
      'rk-carousel-arrow--higher': _vm.arrowsHigher,
      'rk-carousel-arrow--disabled': _vm.current === _vm.slideComplete,
      hidden: !_vm.arrowsOnSmall,
    },attrs:{"type":"button"},on:{"click":function($event){return _vm.slider.next()}}},[_vm._t("nextArrow",function(){return [_c('div',{staticClass:"bg-white px-2 py-1",class:{ 'border border-gray-700': !_vm.arrowsNoBorder }},[_c('RkIcon',{staticClass:"h-8 w-8",attrs:{"icon":"arrow-right"}}),_vm._v(" "),_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Next')))])],1)]})],2):_vm._e(),_vm._v(" "),(_vm.slider && _vm.showPager)?_c('div',{staticClass:"rk-carousel-pager hidden items-center justify-center gap-3 py-2 md:flex"},_vm._l((_vm.pagerHelper),function(_slide,idx){return _c('button',{key:idx,staticClass:"rk-carousel-dot h-3 w-3 rounded-full border transition-colors",class:{
        page: true,
        'rk-carousel-dot--active ': idx === _vm.sliderActivePage,
      },on:{"click":function($event){return _vm.pagerMoveTo(idx)}}},[_c('span',{staticClass:"sr-only"},[_vm._v(_vm._s(_vm.$t('Page'))+" "+_vm._s(idx + 1))])])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./RkCarousel.vue?vue&type=template&id=13456d99"
import script from "./RkCarousel.vue?vue&type=script&lang=ts"
export * from "./RkCarousel.vue?vue&type=script&lang=ts"
import style0 from "./RkCarousel.vue?vue&type=style&index=0&id=13456d99&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkIcon: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkIcon/RkIcon.vue').default})

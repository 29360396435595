




































import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'RkPromoBar',
  props: ['promos'],
  setup() {
    const modalActive = ref(false);
    const modalItem = ref(null);

    function showModal(item) {
      modalItem.value = item;
      modalActive.value = true;
    }
    function closeModal() {
      modalItem.value = null;
      modalActive.value = false;
    }

    return {
      carouselSettings: {
        loop: true,
        mode: 'snap',
        breakpoints: {
          '(min-width: 768px)': {
            slides: { perView: 1, spacing: 0 },
          },
          '(min-width: 1024px)': {
            disabled: true,
            slides: {
              perView: 3,
            },
          },
        },
        slides: {
          perView: 1,
          spacing: 0,
          mode: 'snap',
        },
      },
      modalActive,
      modalItem,
      showModal,
      closeModal,
    };
  },
});













































































import {
  computed,
  defineComponent,
  nextTick,
  onBeforeUnmount,
  onMounted,
  ref,
  watch,
} from '@nuxtjs/composition-api';
import 'keen-slider/keen-slider.min.css';
import KeenSlider from 'keen-slider';

export default defineComponent({
  name: 'RkCarousel',
  props: {
    settings: {
      type: Object,
      default: () => ({}),
    },
    arrows: {
      type: Boolean,
      default: true,
    },
    arrowsInside: {
      type: Boolean,
      default: false,
    },
    arrowsOnSmall: {
      type: Boolean,
      default: false,
    },
    arrowsNoBorder: {
      type: Boolean,
      default: false,
    },
    arrowsHigher: {
      type: Boolean,
      default: false,
    },
    showPager: {
      type: Boolean,
      default: false,
    },
    autoPlay: {
      type: Boolean,
      default: false,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const current = ref(0);
    const slider = ref(null);
    const keen = ref(null);
    const hasInteraction = ref(false);

    const sliderLoading = ref(true);
    const defaultSettings = ref({
      loop: false,
      mode: 'free-snap',
      breakpoints: {
        '(min-width: 768px)': {
          slides: { perView: 2, spacing: 15 },
        },
        '(min-width: 1024px)': {
          drag: false,
          slides: { perView: 4, spacing: 20 },
        },
      },
      slides: {
        perView: 1,
        spacing: 0,
        mode: 'free-snap',
      },
    });

    const sliderLength = computed(() => slider.value?.slides.length);
    const sliderPerView = computed(() => slider.value?.options.slides.perView);
    const sliderPages = computed(() =>
      slider.value ? Math.round(sliderLength.value / sliderPerView.value) : 0
    );
    const sliderActivePage = ref(0);

    const sliderDisabled = computed(() =>
      !sliderLoading.value ? slider.value?.options?.disabled : true
    );
    const slideComplete = computed(() => {
      return !sliderLoading.value
        ? slider.value?.slides?.length - slider.value?.options?.slides?.perView
        : 0;
    });
    const allSlidesShowing = computed(() => {
      return !sliderLoading.value
        ? slider.value?.slides?.length <= slider.value?.options?.slides?.perView
        : true;
    });
    const mergedOptions: any = computed(() => ({
      ...defaultSettings,
      ...props.settings,
    }));
    const showWhenLoading = computed(() => {
      return (
        ' rk-carousel--show-' +
        mergedOptions.value?.slides?.perView +
        ' rk-carousel--show-md-' +
        mergedOptions.value?.breakpoints['(min-width: 768px)'].slides?.perView +
        ' rk-carousel--show-lg-' +
        mergedOptions.value?.breakpoints['(min-width: 1024px)'].slides?.perView
      );
    });

    const pagerHelper = computed(() => {
      return slider.value ? [...Array(sliderPages.value).keys()] : [];
    });

    const pagerMoveTo = (idx: number) => {
      if (idx === 0) {
        slider.value.moveToIdx(0);
      } else if (idx + 1 === pagerHelper.value.length) {
        slider.value.moveToIdx(Math.round(sliderLength.value - sliderPerView.value));
      } else {
        slider.value.moveToIdx(Math.round(idx * sliderPerView.value));
      }
    };

    const reloadSlider = () => {
      slider.value.update();
    };

    watch(
      () => props.images,
      (val, oldVal) => {
        if (!sliderLoading.value && val != oldVal) {
          nextTick(() => {
            reloadSlider();
          });
        }
      },
      { immediate: true, deep: true }
    );

    onMounted(() => {
      const keenSlider = new KeenSlider(
        keen.value,
        {
          ...mergedOptions.value,
          initial: current.value,
        },
        [
          (slider) => {
            let timeout;
            let mouseOver = false;
            function clearNextTimeout() {
              clearTimeout(timeout);
            }
            function sliderDragged() {
              hasInteraction.value = true;
            }
            function nextTimeout() {
              clearTimeout(timeout);
              if (mouseOver) return;
              if (hasInteraction.value) return;
              timeout = setTimeout(() => {
                slider.next();
              }, 3000);
            }
            slider.on('created', () => {
              sliderLoading.value = false;
              if (props.autoPlay) {
                slider.container.addEventListener('mouseover', () => {
                  mouseOver = true;
                  clearNextTimeout();
                });
                slider.container.addEventListener('mouseout', () => {
                  mouseOver = false;
                  nextTimeout();
                });
                nextTimeout();
              }
            });
            if (props.autoPlay) {
              slider.on('dragStarted', sliderDragged);
              slider.on('animationEnded', nextTimeout);
              slider.on('updated', nextTimeout);
            }
            slider.on('slideChanged', (s) => {
              current.value = s.track.details.rel;
              sliderActivePage.value = Math.round(s.track.details.rel / sliderPerView.value);
            });
          },
        ]
      );

      slider.value = keenSlider;
    });
    onBeforeUnmount(() => {
      if (slider.value) slider.value.destroy();
    });

    return {
      current,
      keen,
      pagerHelper,
      reloadSlider,
      showWhenLoading,
      slideComplete,
      slider,
      sliderDisabled,
      sliderLoading,
      pagerMoveTo,
      sliderActivePage,
      allSlidesShowing,
    };
  },
});











import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  props: ['blok'],
  setup() {
    const bgColor = (backgroundColor) => {
      return `bg-${backgroundColor}`;
    };
    const textColor = (backgroundColor) => {
      switch (backgroundColor) {
        case `primary-base`:
          return 'text-white';
        case 'primary-dark':
          return 'text-white';
        case 'primary-light':
          return 'text-white';
        case 'secondary':
          return 'text-white';
        case 'white':
          return 'text-black';
        case 'black':
          return 'text-white';
        default:
          return 'text-white';
      }
    };
    return {
      bgColor,
      textColor,
    };
  },
});

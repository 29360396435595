//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { focusTrap } from '~/rockitUI/components/utilities/directives';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { isClient } from '~/rockitUI/components/utilities/helpers';
import {
  defineComponent,
  ref,
  computed,
  onBeforeUnmount,
  nextTick,
  watch,
} from '@nuxtjs/composition-api';
export default defineComponent({
  name: 'RkModal',
  directives: { focusTrap, clickOutside },
  model: {
    prop: 'visible',
    event: 'close',
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    cross: {
      type: Boolean,
      default: true,
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    transitionOverlay: {
      type: String,
      default: 'rk-fade',
    },
    transitionModal: {
      type: String,
      default: 'rk-fade',
    },
    width: {
      type: String,
      default: 'full',
      validator(value) {
        const widths = ['full', 'narrow'];
        return widths.includes(value);
      },
    },
  },
  setup(props, { slots, emit }) {
    const content = ref(null);

    const hasModalBar = computed(() => {
      return slots.hasOwnProperty('modal-bar');
    });

    const close = () => {
      emit('close', false);
    };
    const checkPersistence = () => {
      if (!props.persistent) {
        close();
      }
    };
    const keydownHandler = (e) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        close();
      }
    };
    const widthClass = (width) => {
      switch (width) {
        case 'narrow':
          return 'sm:max-w-lg';
        default:
          return;
      }
    };

    watch(
      () => props.visible,
      (value) => {
        if (!isClient) return;
        if (value) {
          nextTick(() => {
            disableBodyScroll(content.value);
          });
          document.addEventListener('keydown', keydownHandler);
        } else {
          clearAllBodyScrollLocks();
          document.removeEventListener('keydown', keydownHandler);
        }
      },
      { immediate: true }
    );

    onBeforeUnmount(() => {
      clearAllBodyScrollLocks();
    });

    return {
      content,
      hasModalBar,
      close,
      checkPersistence,
      keydownHandler,
      widthClass,
    };
  },
});

import { render, staticRenderFns } from "./RkPromoBar.vue?vue&type=template&id=8a7780a4"
import script from "./RkPromoBar.vue?vue&type=script&lang=ts"
export * from "./RkPromoBar.vue?vue&type=script&lang=ts"
import style0 from "./RkPromoBar.vue?vue&type=style&index=0&id=8a7780a4&prod&lang=postcss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RkIcon: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkIcon/RkIcon.vue').default,RkCarouselItem: require('/var/www_vsf/htdocs/rockitUI/components/components/organisms/RkCarousel/_internal/RkCarouselItem.vue').default,RkCarousel: require('/var/www_vsf/htdocs/rockitUI/components/components/organisms/RkCarousel/RkCarousel.vue').default,RkSiteWrapper: require('/var/www_vsf/htdocs/rockitUI/components/components/atoms/RkSiteWrapper/RkSiteWrapper.vue').default,RkModal: require('/var/www_vsf/htdocs/rockitUI/components/components/molecules/RkModal/RkModal.vue').default})
